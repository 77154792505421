<template>
    <div class="full-page" :class="{ 'nav-open': $sidebar.showSidebar }">
        <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
            <div class="md-toolbar-row md-offset">
                <div class="md-toolbar-section-start">
                    <h3 class="md-title">{{ $route.name }}</h3>
                </div>
                <div class="md-toolbar-section-end">
                    <md-button
                        class="md-just-icon md-simple md-round md-toolbar-toggle"
                        :class="{ toggled: $sidebar.showSidebar }"
                        @click="toggleSidebar"
                    >
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </md-button>
                </div>
            </div>
        </md-toolbar>
        <div class="wrapper wrapper-full-page" @click="toggleSidebarPage">
            <div
                class="page-header header-filter"
                :class="setPageClass"
                filter-color="black"
            >
                <div class="container md-offset">
                    <zoom-center-transition
                        :duration="pageTransitionDuration"
                        mode="out-in"
                    >
                        <router-view></router-view>
                    </zoom-center-transition>
                </div>
            </div>
        </div>
        <img class="image-footer" src="img/vector/login_footer.svg"  alt="footer"/>
    </div>
</template>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
import EventBus from "@/EventBus";

export default {
    components: {
        ZoomCenterTransition
    },
    props: {
        backgroundColor: {
            type: String,
            default: "black"
        }
    },
    inject: {
        autoClose: {
            default: true
        }
    },
    data() {
        return {
            languageName: "English",
            responsive: false,
            showMenu: false,
            menuTransitionDuration: 250,
            pageTransitionDuration: 300,
            year: new Date().getFullYear()
        };
    },
    computed: {
        setBgImage() {
            let images = {
                Pricing: "./img/bg-pricing.jpg",
                Login: "./img/login.jpg",
                Register: "./img/register.jpg",
                Lock: "./img/lock.jpg"
            };
            return {
                backgroundImage: `url(${images[this.$route.name]})`
            };
        },
        setPageClass() {
            return `${this.$route.name}-page`.toLowerCase();
        }
    },
    methods: {
        chooseLanguageOptionInModal() {
            this.$refs["wrapLanguageOption"].style.position = "absolute";
            this.$refs["wrapLanguageOption"].style.display = "block";
            this.$refs["wrapLanguageOption"].style.visibility = "visible";
        },
        handleClickOutSideLanguageInModal() {
            this.$refs["wrapLanguageOption"].style.display = "none";
            this.$refs["wrapLanguageOption"].style.visibility = "hidden";
        },
        changeLanguage(value, valueInVietNamLanguage) {
            var self = this
            setTimeout(() => {
                self.handleClickOutSideLanguageInModal();
            }, 100);
            EventBus.$emit("changeLanguage", value);
            this.languageName = valueInVietNamLanguage
        },
        toggleSidebarPage() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        linkClick() {
            if (
                this.autoClose &&
                this.$sidebar &&
                this.$sidebar.showSidebar === true
            ) {
                this.$sidebar.displaySidebar(false);
            }
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        toggleNavbar() {
            document.body.classList.toggle("nav-open");
            this.showMenu = !this.showMenu;
        },
        closeMenu() {
            document.body.classList.remove("nav-open");
            this.showMenu = false;
        },
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        }
    },
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
        this.languageName = localStorage.lang == "vn" ? "Tiếng Việt" : "English"
        if (localStorage.lang != "vn") {
            localStorage.setItem("lang", "en")        
        }
    },
    beforeDestroy() {
        this.closeMenu();
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    beforeRouteUpdate(to, from, next) {
        // Close the mobile menu first then transition to next page
        if (this.showMenu) {
            this.closeMenu();
            setTimeout(() => {
                next();
            }, this.menuTransitionDuration);
        } else {
            next();
        }
    }
};
</script>
<style lang="scss" scoped>
.wrap {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 999999;
    .language {
        height: 56px;
        display: flex;
        justify-content: space-around;
        border-radius: 8px;
        background-color: #43a047;
        padding: 16px 24px;
        position: relative;
        color: #ffffff;
        .material-icons {
            margin-right: 8px;
        }
        .WrapOption {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 8px;
            z-index: 9999;
            max-height: 320px;
            position: absolute;
            width: 212px;
            background: #ffffff;
            box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
                0px 3px 4px rgba(10, 31, 68, 0.1);
            border-radius: 10px;
            top: 60px;
            left: 0;
            visibility: hidden;
            .Option {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 8px 16px;
                width: 100%;
                height: 36px;
                border-radius: 6px;
                font-family: "Roboto";
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                &:hover {
                    background-color: #f6f9ff;
                }
            }
        }
    }
}

$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
        opacity: 1;
    }
}
.wrapper-full-page .zoomIn {
    animation-name: zoomIn8;
}
@keyframes zoomOut8 {
    from {
        opacity: 1;
        transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
    }
    to {
        opacity: 0;
        transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
    }
}
.wrapper-full-page .zoomOut {
    animation-name: zoomOut8;
}
.page-header::before {
    background-color: #fff;
}

.image-footer {
    width: 100%;
    position: absolute;
    bottom: 0px;
    z-index: 999;
}   
</style>
